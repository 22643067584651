import { useLocation, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Tooltip, Button } from "@mui/material";
import { authenticateProdgain, formatDate, formatMonth } from "../../utils/common";
import mailImg from "../../assets/mail_icon.svg";
import downloadImg from "../../assets/download_icon.svg";
import styles from "./styles.module.css";
import RenewDrawer from "../../components/Common/RenewDrawer";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAuth } from "../../context/authContext/Auth";
import { SET_LIBRARY_CREDITS } from "../Redux/action";
import { Modal, Button as ButtonX } from "antd";
import axios from "axios";
import API from "../../api/Api";

export const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  // Capitalize the first letter ignoring the 'and' in the string
  return string.replace(/\w\S*/g, function (txt) {
    if (txt === "and") return txt;
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

function ReportBriefDetail({ hub, synapseReportData, fetchReport }) {
  const navigate = useNavigate();
  const location = useLocation();

  const cameFrom = (() => {
    if (!location?.state?.cameFrom) return "Reports";
    const directed = {
      MY_REPORTS: "My Reports",
      CATALOGUE: "On-Demand Reports",
      BOOKMARK: "Bookmark Report",
      PURCHASED_HISTORY: "Purchased History",
      REPORTS_LIBRARY: "Library",
    };
    return directed[location.state.cameFrom] ?? "Reports";
  })();

  const handleBack = () => navigate(-1);
  if (!synapseReportData) handleBack();

  const [downloadLoading, setDownloadLoading] = useState("IDLE");
  const { userInfo } = useAuth();

  const { remaining_library_credit } = useSelector((state) => state);

  const {
    hub_id,
    title,
    study_period = [2017, 2029],
    report_sample_url,
    domain,
    subscribed,
    report_expiry,
    published,
    subscribed_on,
    synapse_report_id,
    forecast_year,
    is_requested_library_report,
    page_number,
  } = synapseReportData;

  const downloadReportSample = async () =>
    await forceDownload(report_sample_url, title, setDownloadLoading);

  const purchaseStatusFactory = {
    yes: (
      <PurchasedReport
        report_expiry={report_expiry}
        report_id={synapse_report_id}
        title={title}
        info={userInfo?.account_type}
        access_type={userInfo?.access_type}
        viewOnlyFullAccess={userInfo?.viewOnlyFullAccess}
        subscribed={subscribed}
        remaining_library_credit={remaining_library_credit}
        is_requested_library_report={is_requested_library_report}
        fetchReport={fetchReport}
      />
    ),
    no: (
      <UnPurchasedReport
        report_id={synapse_report_id}
        title={title}
        downloadSample={downloadReportSample}
        loading={downloadLoading}
        report_sample_url={report_sample_url}
        info={userInfo?.account_type}
        access_type={userInfo?.access_type}
        viewOnlyFullAccess={userInfo?.viewOnlyFullAccess}
        subscribed={subscribed}
        remaining_library_credit={remaining_library_credit}
        is_requested_library_report={is_requested_library_report}
        fetchReport={fetchReport}
      />
    ),
    error: <></>,
  };

  return (
    <section
      className={styles.details}
      style={{ width: "90%", margin: "auto", marginTop: "-244px" }}
    >
      <Box sx={{ display: "flex", alignItems: "center", color: "#5a5a5a" }}>
        <div style={{ cursor: "pointer" }} onClick={handleBack}>
          <span>
            <ArrowBackIosIcon sx={{ color: "#03b2cb", fontSize: "12px" }} />{" "}
          </span>
        </div>
        <div>
          <Tooltip title="Go back">
            <span
              style={{
                cursor: "pointer",
              }}
              onClick={handleBack}
            >
              {cameFrom}
            </span>{" "}
          </Tooltip>
        </div>
      </Box>
      <div>
        <h1 style={{ marginBottom: "10px" }}>{title?.toUpperCase()}</h1>
        <small className={styles.reportSmallDetails}>
          <ul style={{ margin: 0 }}>
            {/* Hide Published In */}
            {published &&
              (published != null ? (
                <li>Published In: {formatMonth(published)}</li>
              ) : null)}
            {subscribed_on && (
              <li>Purchased On: {formatDate(subscribed_on)}</li>
            )}
            {report_expiry && <li>Renewal On: {formatDate(report_expiry)}</li>}
          </ul>
        </small>
      </div>
      {/* {study_period && !study_period.includes("null") && (
        <div style={{ maxHeight: "fit-content" }}>
          <p style={{ margin: 0 }}>
            <span>Study Period:</span> {study_period}
          </p>
        </div>
      )} */}
      {forecast_year && !forecast_year.includes("null") && (
        <div style={{ maxHeight: "fit-content" }}>
          <p style={{ margin: 0 }}>
            <span>Forecast Year:</span> {forecast_year}
          </p>
        </div>
      )}
      {page_number ? (
        <div style={{ maxHeight: "fit-content" }}>
          <p style={{ margin: 0 }}>
            <span>Number of Pages:</span> {page_number}
          </p>
        </div>
      ) : null}
      {/* <div className={styles.btnContainer}>
        {
          purchaseStatusFactory[
            subscribed || (!synapse_report_id ? "error" : "no")
          ]
        }
      </div> */}
    </section>
  );
}

export default ReportBriefDetail;

const PurchasedReport = ({
  report_expiry,
  title,
  report_id,
  access_type,
  viewOnlyFullAccess,
  subscribed,
  remaining_library_credit,
  is_requested_library_report,
  fetchReport,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const expiryDate = new Date(report_expiry);
  const oneMonthFromNow = new Date();
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
  return (
    <>
      {!is_requested_library_report ? (
        <GetAccess
          subscribed={subscribed}
          remaining_library_credit={remaining_library_credit}
          is_requested_library_report={is_requested_library_report}
          report_expiry={report_expiry}
          title={title}
          report_id={report_id}
          fetchReport={fetchReport}
        />
      ) : null}

      {expiryDate <= oneMonthFromNow &&
        !is_requested_library_report &&
        remaining_library_credit == 0 && (
          <button
            onClick={() => {
              setDrawerOpen(true);
              setTypeModal("renewal");
            }}
          >
            Renew
          </button>
        )}
      <RenewDrawer
        report_id={report_id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={typeModal}
      />
      <button
        onClick={() => {
          setDrawerOpen(true);
          setTypeModal("contact");
        }}
      >
        <img src={mailImg} alt="ask query" />
        Ask Your Query
      </button>
    </>
  );
};

const UnPurchasedReport = ({
  downloadSample,
  loading,
  report_expiry,
  title,
  report_id,
  report_sample_url,
  info,
  access_type,
  viewOnlyFullAccess,
  subscribed,
  remaining_library_credit,
  is_requested_library_report,
  fetchReport,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  return (
    <>
      {!is_requested_library_report ? (
        <GetAccess
          subscribed={subscribed}
          remaining_library_credit={remaining_library_credit}
          is_requested_library_report={is_requested_library_report}
          report_expiry={report_expiry}
          title={title}
          report_id={report_id}
          fetchReport={fetchReport}
        />
      ) : null}

      {!is_requested_library_report && remaining_library_credit == 0 && (
        <button
          onClick={() => {
            setDrawerOpen(true);
            setTypeModal("buy");
          }}
        >
          Buy Now
        </button>
      )}
      <RenewDrawer
        report_id={report_id}
        title={title}
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        typeModal={typeModal}
      />
      {report_sample_url &&
        info !== "view only" &&
        ((access_type === "full" && viewOnlyFullAccess.includes(report_id)) ||
          access_type !== "full") && (
          <button onClick={downloadSample} disabled={loading === "PENDING"}>
            {loading === "PENDING" ? (
              <CircularProgress size={"20px"} />
            ) : (
              <>
                <img src={downloadImg} alt="download" />
                Download Sample
              </>
            )}
          </button>
        )}
      <button
        onClick={() => {
          setDrawerOpen(true);
          setTypeModal("contact");
        }}
      >
        <img src={mailImg} alt="ask query" />
        Ask Your Query
      </button>
    </>
  );
};

export const GetAccess = ({
  subscribed,
  remaining_library_credit,
  is_requested_library_report,
  report_expiry,
  title,
  report_id,
  fetchReport,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [requestStatus, setRequestStatus] = useState("uninitialise");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { Base_URL } = API;
  const { config } = useAuth();
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setRequestStatus("uninitialise");
    setRequestMessage("");
  };

  const sendRequest = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    axios({
      url: `${Base_URL}/api/users/decrease-library-credit`,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        authorization: config.headers.authorization,
      },
      data: {
        report_id: report_id,
        message: requestMessage,
      },
    })
      .then(async (res) => {
        setRequestStatus("success");
        dispatch({
          type: SET_LIBRARY_CREDITS,
          payload: {
            total_credit: res.data.data.credit.total,
            remaining_credit: res.data.data.credit.remaining,
          },
        });
        await authenticateProdgain();
        fetchReport();
      })
      .catch((error) => {
        setRequestStatus("failure");
        console.error(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  if (subscribed === "yes") {
    return null;
  } else if (remaining_library_credit == 0) {
    return null;
  } else if (is_requested_library_report) {
    return null;
  } else {
    return (
      <>
        <Tooltip title="Access This Report" placement="top-start">
          <Button
            sx={{
              backgroundColor: "#189CDE",
              textTransform: "capitalize",
            }}
            onClick={() => {
              showModal();
            }}
            variant="contained"
          >
            Get Access
          </Button>
        </Tooltip>

        <Modal
          title={`${title.toUpperCase()}`}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"800px"}
          styles={{
            width: "800px",
          }}
          footer={null}
        >
          <div className="request-modal">
            {requestStatus === "success" && (
              <div>
                <h1
                  style={{
                    color: "#1BB1F0",
                    fontSize: "32px",
                    fontWeight: "bold",
                  }}
                >
                  Report Unlocked!
                </h1>
                <p className="description">
                  Close this window to start reading.
                </p>
                <p className="remaining-credits">
                  Remaining Library Credits :
                  <span>{`${remaining_library_credit}`}</span>
                </p>
              </div>
            )}
            {requestStatus === "uninitialise" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <h3
                  style={{
                    marginBottom: "20px",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                >
                  Viewing this report will cost 1 credit.
                </h3>
                <p
                  style={{
                    marginBottom: "20px",
                    color: "rgb(125, 125, 125)",
                    fontWeight: "400",
                    fontSize: "16px",
                  }}
                >
                  {" "}
                  Current Library Credits :
                  <span style={{ color: "#1BB1F0" }}>
                    {remaining_library_credit}
                  </span>
                </p>

                <ButtonX onClick={sendRequest} disabled={isButtonDisabled}>
                  Get Access
                </ButtonX>
              </div>
            )}

            {requestStatus === "failure" && (
              <div>
                <h1 style={{ color: "rgba(255, 0, 0, 0.7)" }}>
                  Something Went Wrong!
                </h1>
                <p className="description">Please try in some time.</p>
                <p className="remaining-credits">
                  Your credits do not decrease.
                </p>
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  }
};

async function forceDownload(pdf_url, pdf_name, setLoading) {
  setLoading("PENDING");
  return new Promise(function (resolve, reject) {
    var x = new XMLHttpRequest();
    x.open("GET", pdf_url, true);
    x.responseType = "blob";
    x.onload = function (e) {
      resolve(x.response);
    };
    x.onerror = function () {
      reject(new Error("Error occurred while downloading PDF."));
    };
    x.send();
  })
    .then(function (pdf_blob) {
      saveAs(pdf_blob, pdf_name, "application/pdf");
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(() => {
      setLoading("IDLE");
    });
}
