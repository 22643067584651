import { Link } from "react-router-dom";
import styles from "./Login.module.css";
import { useAuth } from "../../context/authContext/Auth";
import { LinearProgress } from "@mui/material";
import Notification from "../../components/Common/Notification";
import { useState, useMemo, useEffect } from "react";
import API from "../../api/Api";
import Cookies from "universal-cookie";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import PasswordVisibilityInput from "../../components/Common/PasswordVisibiltyInput";
import LabelInputField from "../../components/Common/LabelInputField";
import { authenticateProdgain } from "../../utils/common";

function Login() {
  const { setNotify, notify } = useAuth();

  const { Base_URL, login } = API;
  const cookies = new Cookies();

  const navigate = useNavigate();
  const { search } = useLocation();
  const account_activate = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    if (account_activate.get("acc_activate"))
      axios
        .post(
          Base_URL +
            "/api/users/activate-account/" +
            account_activate.get("acc_activate")
        )
        .then(() => {
          navigate("/", { replace: true });
        });
  }, []);

  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState(errorsState);
  const [loading, setLoading] = useState("IDEAL");
  const { email, password } = formState;

  const handleFormChange = (e) => {
    setFormState((p) => ({
      ...p,
      [e.target.name]: e.target.value.trim(),
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const email = formState.email,
      password = formState.password;
    let error = {};
    if (!emailRegex.test(email)) {
      error = { ...error, err_email: "Invalid Email" };
      setErrors(error);
    }

    if (!password || password.length < 8) {
      error = {
        ...error,
        err_password: "The password you entered doesn’t have 8 characters",
      };
      setErrors(error);
    }

    if (Object.keys(error).length) return;

    setLoading("PENDING");
    axios
      .post(`${Base_URL}${login}`, formState)
      .then((res) => {
        cookies.set("TOKEN", res.data?.token, {
          path: "/",
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 90), // cookie expires in 90 days of login
        });
        // REDIRECTING TO REPORTS PAGE FOR ALL
        if(res.data.access_type === 'trial' && res.data.account_created_through === "cms") {
          navigate("/reports?tab=REPORTS_LIBRARY", { replace: true });
        } else {
          navigate("/reports?tab=MY_REPORTS", { replace: true });
        }
        // if(res.data?.access_type !== 'trial') {
        //   navigate("/reports", { replace: true }); // PAID USERS WILL BE REDIRECTED TO REPORTS PAGE
        // } else {
        //   navigate("/hubs", { replace: true });
        // }
        authenticateProdgain()
      })
      .catch((err) => {
        setErrors(errorsState);
        console.log(err);
        setNotify({
          isOpen: true,
          message: err?.response?.data?.message ?? "Some error occurred",
          type: "error",
          position: "right",
        });
      })
      .finally(() => {
        setLoading("IDEAL");
      });

    return;
  };

  return (
    <>
      <Notification notify={notify} setNotify={setNotify} />
      <>
        <div className={styles.detailsBox}>
          <h1>Log in to Synapse</h1>
          <div className={styles.inputContainer}>
            <form onSubmit={handleFormSubmit}>
              <LabelInputField>
                <LabelInputField.Label>
                  Email
                  <LabelInputField.Tooltip
                    value={"Enter your registered email"}
                  />
                </LabelInputField.Label>
                <LabelInputField.Input
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => handleFormChange(e)}
                />
                <LabelInputField.Error errors={errors.err_email} />
              </LabelInputField>
              <LabelInputField>
                <LabelInputField.Label>
                  Password
                  <LabelInputField.Tooltip value={"Enter your password"} />
                </LabelInputField.Label>
                <LabelInputField.CustomInput>
                  <PasswordVisibilityInput
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => handleFormChange(e)}
                  />
                </LabelInputField.CustomInput>
                <LabelInputField.Error errors={errors.err_password} />
              </LabelInputField>
              <div className={styles.actionContainer}>
                <div>
                  <input type="checkbox" name="remember_me" />
                  <label htmlFor="remember_me">Remember me</label>
                </div>
                <div>
                  <Link to="/forgot-password">Forgot Password</Link>
                </div>
              </div>
              <div className={styles.loginContainer}>
                <button type="submit">Login</button>
                {loading === "PENDING" && <LinearProgress />}
                <p>
                  Don't have an account? <Link to="/register">Register</Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </>
    </>
  );
}

export default Login;

const initialState = {
  email: "",
  password: "",
};

const errorsState = { err_email: "", err_password: "" };
