import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, CircularProgress, Typography } from "@mui/material";
import { useAuth } from "../../context/authContext/Auth";
import LockBoxNew from "../LockBox/LockBoxNew";
import { useSelector } from "react-redux";
import { ReportAccess } from "../ArchiveReportDetails/Modals/ReportAccess";
import { useDispatch } from "react-redux";
import { setCookie } from "../../utils/common";

const AnswerEngine = ({
  hubId,
  libraryReportId = null,
  libraryReportPurchased = null,
  subscribed,
  fetchReport,
  title,
  id,
  from = "library",
  uploadId,
}) => {

  const location = useLocation();

  const { userInfo } = useAuth();
  const [loading, setLoading] = useState(false);
  const iframeContainerRef = useRef(null);
  const { remaining_library_credit, remaining_ondemand_credit } = useSelector((state) => state);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleCancel = () => {
    setShowModal(false);
    // fetchReport();
  };
  useEffect(() => {
    if (!userInfo) return;
    
    if(from === "global") {
      dispatch({
        type: "SET_NAVBAR_DATA",
        payload: {
          heading: null,
          paragraph: null,
          bannerHeight: "140px",
        },
      })
    }


    setLoading(true);
    const authenticate = async () => {
      try {

        switch (from) {
          case "global":
            setCookie("selectedHub", null);
            setCookie("uploadId", null);
            break;
          case "hub":
            setCookie("selectedHub", hubId);
            setCookie("uploadId", null);
            break;
          case "hub_report":
            setCookie("selectedHub", hubId);
            setCookie("uploadId", uploadId);
            break;
          case "flash":
          case "library":
            setCookie("selectedHub", null);
            setCookie("uploadId", uploadId);
            break;
          default:
            console.error("Invalid 'from' prop");
            break;
        }

        setLoading(false);

        // Handle successful response
      } catch (error) {
        console.error("Error during authentication:", error);
        setLoading(false);
        // Handle error
      }
    };

    // Call the function when component mounts
    authenticate();
  }, [libraryReportId, userInfo, libraryReportPurchased]);

  const requiredIframeDomain = {
    "synapse.mordorlabs.com": "https://myra-chat.mordorlabs.com",
    "synapse.mordorintelligence.com": "https://myra-chat.mordorintelligence.com",
    "v2-synapse.mordorintelligence.com": "https://myra-chat.mordorintelligence.com",
    localhost: "",
  };

  let iFrameSrc = requiredIframeDomain[window.location.hostname];

  const handleIframeLoad = () => {
    if (iframeContainerRef.current) {
      iframeContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  function whatModelToShow() {
    if (from === "library" && remaining_library_credit > 0) {
      return () => setShowModal(true);
    }
    if (
      from === "hub_report" &&
      location.state?.cameFrom === "REPORTS_LIBRARY" &&
      remaining_library_credit > 0
    ) {
      return () => setShowModal(true);
    }

    if (
      (from === "hub_report" || from === "flash") &&
      location.state?.cameFrom !== "REPORTS_LIBRARY" &&
      remaining_ondemand_credit > 0
    ) {
      return () => setShowModal(true);
    }

    return false;
  }

  const runThisFunctionWhenClickOnLockBox = whatModelToShow();


  return (
    <div style={{ position: "relative" }}>
      {!subscribed && id && (
        <>
          {" "}
          <LockBoxNew
            onclick={runThisFunctionWhenClickOnLockBox}
            componentName={"MyRA AI"}
          />
        </>
      )}
      {showModal && (
        <ReportAccess 
        report_id={id} 
        title={title} 
        closeModal={handleCancel}
        reloadFunction={fetchReport}
        from={from}
        isLibraryHubReport={from === "hub_report" && location.state?.cameFrom === "REPORTS_LIBRARY"}
         />
      )}

      <Container
        ref={iframeContainerRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
          width: "90%",
          filter: id && !subscribed ? "blur(5px)" : "none",
          pointerEvents: id && !subscribed ? "none" : "unset",
        }}
      >
        {loading ? (
          <>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Authenticating...
            </Typography>
          </>
        ) : (
          // <Typography>{showData}</Typography>
          <>
            {/* <ChatUi /> */}
            <div
              style={{ height: "100vh", width: "100vw", margin: 0, padding: 0 }}
            >
              <iframe
                title="Answer Engine"
                style={{
                  height: "100%",
                  width: "100%",
                  border: "none",
                  margin: 0,
                  padding: 0,
                }}
                src={iFrameSrc}
                onLoad={handleIframeLoad}
              ></iframe>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default AnswerEngine;
