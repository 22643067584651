import { useEffect, useState } from "react";
import styles from "./style.module.css";
const correctImg = "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/correct.svg";
const wrongImg = "https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/wrong.svg";

const PasswordValidation = ({ password, setErrors }) => {
  const [passwordAuth, setPasswordAuth] = useState({
    allowedLength: false,
    isAlphaNum: false,
    specialChar: false,
    combChar: false,
  });

  const checkForLength = (pass) => pass.length > 7;
  const checkForAlphaNum = (pass) => /^(?=.*[0-9])(?=.*[a-zA-Z]).+$/.test(pass);
  const checkForSpecialChar = (pass) =>
    /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(pass);
  const checkForMixedChar = (pass) => /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(pass);

  useEffect(() => {
    const passError = {
      allowedLength: checkForLength(password),
      isAlphaNum: checkForAlphaNum(password),
      specialChar: checkForSpecialChar(password),
      combChar: checkForMixedChar(password),
    };
    setPasswordAuth(passError);
    if (Object.values(passError).every((item) => item === true)) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [password]);

  const Img = ({ allow }) => {
    return <img src={allow ? correctImg : wrongImg} alt="access pass" />;
  };

  const { allowedLength, isAlphaNum, specialChar, combChar } = passwordAuth;

  return (
    <>
      <ul className={styles.validPassContainer}>
        <li>
          <Img allow={allowedLength} />
          <span>Use at least 8 characters</span>
        </li>
        <li>
          <Img allow={isAlphaNum} />
          <span>Combination of alpha numeric characters (Abc12)</span>
        </li>
        <li>
          <Img allow={specialChar} />
          <span>Combination of special character (@#$)</span>
        </li>
        <li>
          <Img allow={combChar} />
          <span>Combination of one capital & small letter (Ab)</span>
        </li>
      </ul>
    </>
  );
};

export default PasswordValidation;
