import { getPdfUrlUnSigned } from "../../utils/common";

const initialState = {
  reportID: null,
  hubID: null,
  dashboardData: null,
  datasheetReportId: null,
  dataSheetData: null,
  heading: null,
  paragraph: null,
  backButtonData: null,
  talkToUs: null,
  downloadBrochure: null,
  companiesPage: false,
  bannerHeight: null,
  selectedReportDetail: {},
  bookmarkReportsCount: 0,
  bookmarkHubsCount: 0,
  subHeading: "",
  blueHeading: "",
  configUsed: {},
  userData: {},
  hubContentData: {},
  checkSub: false,
  checkHubId: 0,
  total_credit: 0,
  remaining_credit: 0,
  total_ondemand_credit: 0,
  remaining_ondemand_credit: 0,
  total_library_credit: 0,
  remaining_library_credit: 0,
  remaining_myra_credits: 0,
  initialReportBuffer: null,
  currentLibrarySlide: 0,
  hubLevelModule: {
    id: null,
    access_type: "trial",
    modules: [],
  },
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_REPORT_DATA":
      return {
        ...state,
        reportID: action.payload.reportID,
        hubID: action.payload.hubID,
        dashboardData: action.payload.dashboardData,
      };
    case "SET_REPORT_FILE_DATA":
      return {
        ...state,
        datasheetReportId: action.payload.datasheetReportId,
        dataSheetData: action.payload.dataSheetData,
      };
    case "SET_NAVBAR_DATA":
      return {
        ...state,
        heading: action.payload.heading,
        paragraph: action.payload.paragraph,
        backButtonData: action.payload.backButtonData,
        talkToUs: action.payload.talkToUs,
        downloadBrochure: action.payload.downloadBrochure,
        companiesPage: action.payload.companiesPage,
        bannerHeight: action.payload.bannerHeight,
        subHeading: action.payload.subHeading,
        blueHeading: action.payload.blueHeading,
      };
    case "SET_BOOKMARK_REPORTS":
      return {
        ...state,
        bookmarkReportsCount: action.payload.bookmarkReportsCount,
      };
    case "SET_BOOKMARK_HUBS":
      return {
        ...state,
        bookmarkHubsCount: action.payload.bookmarkHubsCount,
      };
    case "SET_CURRENT_REPORT_DETAIL":
      return {
        ...state,
        selectedReportDetail: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        configUsed: action.payload.configUsed,
        userData: action.payload.userData,
      };
    case "SET_HUB_CONTENT":
      return {
        ...state,
        hubContentData: action.payload.hubContentData,
        checkHubId: action.payload.checkHubId,
        hubSlug: action.payload.hubSlug,
      };
    case "SET_CHECK_SUB":
      return {
        ...state,
        checkSub: action.payload.checkSub,
        hubLevelModule: action.payload.hubLevelModule,
      };
    case "SET_CREDITS":
      return {
        ...state,
        total_credit: action.payload.credits?.total_credit,
        remaining_credit: action.payload.credits?.remaining_credit,
        total_ondemand_credit:
          action.payload.credits?.total_ondemand_credit || 0,
        remaining_ondemand_credit:
          action.payload.credits?.remaining_ondemand_credit || 0,
        total_library_credit: action.payload.credits?.total_library_credit || 0,
        remaining_library_credit:
          action.payload.credits?.remaining_library_credit || 0,
        remaining_myra_credits: action.payload.credits?.remaining_myra_credits,
      };
    case "SET_DOWNLOAD_CREDITS":
      return {
        ...state,
        total_credit: action.payload.total_credit,
        remaining_credit: action.payload.remaining_credit,
      };
    case "SET_ONDEMAND_CREDITS":
      return {
        ...state,
        total_ondemand_credit: action.payload.total_credit,
        remaining_ondemand_credit: action.payload.remaining_credit,
      };
    case "SET_LIBRARY_CREDITS":
      return {
        ...state,
        total_library_credit: action.payload.total_credit,
        remaining_library_credit: action.payload.remaining_credit,
      };
    case "SET_INITIAL_REPORT_BUFFER":
      return {
        ...state,
        initialReportBuffer: {
          ...state.initialReportBuffer,
          [getPdfUrlUnSigned(action.payload.url)]: action.payload.data,
        }
      };
    case "SET_CURRENT_LIBRARY_SLIDE":
      return {
        ...state,
        currentLibrarySlide: action.payload,
      };
    case "SET_MYRA_CREDITS":
      return {
        ...state,
        remaining_myra_credits: action.payload,
      };

    default:
      return state;
  }
};

export default rootReducer;
