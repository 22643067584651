import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import Banner from "../../assets/Banner.svg";
import Logo from "../../assets/logo.png";

import Credit from "../../assets/credit.svg";
import RelationshipManager from "../../assets/relationship-manager.png";
import InfoIcon from "../../assets/info_tool_tip.svg"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SynapseLogo from '../../../src/assets/Synapse_logo.svg'

import {
  AppBar,
  Container,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
} from "@mui/material";
import axios from "axios";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftSharpIcon from "@mui/icons-material/ChevronLeftSharp";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { pages } from "../../utils/navdata/NavData";

import Cookies from "universal-cookie";

import { useAuth } from "../../context/authContext/Auth";
import { Download, Email, EmailSharp, MailLockOutlined, Print } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import HubsHomeChatDialog from "./ContactUs";
import API from "../../api/Api";
import RenewDrawer from "../Common/RenewDrawer";
import { authenticateProdgain, getUserMyRaCredits, hubTitleWordsModify, MyRABtn } from "../../utils/common";
import TagManager from "react-gtm-module/dist/TagManager";
import DownloadAccessChecker from "../Common/DownloadOptionCheck/DownloadAccessChecker";

function Navbar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [creditType, setCreditType] = useState("Ondemand");
  const [isCreditInfoModalOpen, setIsCreditInfoModalOpen] = useState(false);
  const [isAnalystInfoModalOpen, setIsAnalystInfoModalOpen] = useState(false);


  const {
    heading,
    paragraph,
    backButtonData,
    talkToUs,
    downloadBrochure,
    companiesPage,
    bannerHeight,
    subHeading,
    blueHeading,
    configUsed,
    userData,
   total_credit,
   remaining_credit,
   total_ondemand_credit,
   remaining_ondemand_credit,
   total_library_credit,
   remaining_library_credit,
   remaining_myra_credits,
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const { notify, setNotify, config, setUserInfo, userInfo } = useAuth();

  const { Base_URL } = API;

  async function getUserDetails() {
    if (configUsed === config) {
      setUserInfo(userData);
    } else {
      try {
        const response = await axios.get(`${Base_URL}/api/users/info`, config);

        if (response?.data.code === 200) {
          setUserInfo(response.data?.data);

          TagManager.dataLayer({
            dataLayer: {
              event: "login",
              user: response.data?.data?.id,
            },
          });
          cookies.set("GAuserId", response.data?.data?.id, { path: "/" });
          dispatch({
            type: "SET_USER_INFO",
            payload: {
              configUsed: config,
              userData: response.data?.data,
            },
          });
          dispatch({
            type: "SET_CREDITS",
            payload: {
              credits:{
                total_credit:              response.data?.data?.credit?.total_credit,
                remaining_credit:          response.data?.data?.credit?.remaining_credit,
                total_ondemand_credit:     response.data?.data?.ondemand_credits?.total,
                remaining_ondemand_credit: response.data?.data?.ondemand_credits?.remaining,
                total_library_credit:      response.data?.data?.library_credits?.total,
                remaining_library_credit:  response.data?.data?.library_credits?.remaining,
                remaining_myra_credits:   response.data?.data?.myra_credits || 0,
              }
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    authenticateProdgain();
    getUserDetails();
  }, []);

  // const myDecodedToken = decodeToken(token);

  const cookies = new Cookies();
  // const { setLogin } = useAuth();

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    getUserMyRaCredits()
    .then(credits => {
      dispatch({
        type: "SET_MYRA_CREDITS",
        payload: credits,
      })
    })
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

 const handleCreditInfo = (credit_type) => {
   setCreditType(credit_type);
   setIsCreditInfoModalOpen(true); 
 }

  // This function only to diable the link
  // const handleClick = (e) => {
  //   e.preventDefault();
  // };

  const menuItemStyle = {
    borderBottom: "1px solid #ccc", // Add a bottom border to menu items
    padding: "16px 24px ", // Add padding to adjust the spacing
  };

  const userToken = cookies.get("TOKEN");

  const [open, setOpen] = useState(false);
  const hasAccess = DownloadAccessChecker( "hub", +cookies.get("hubId") )

  const downloadingBrochure = () => {
    //Call the API to download the brochure
    axios
      .get(
        `
        ${Base_URL}/api/hubs/brochure?hub_id=${downloadBrochure}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        window.open(res.data.data, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };
      // "vivek.sikaria@mordorintelligence.com",
    // "krati@mordorintelligence.com",
    // "rupak.dalapathi@mordorintelligence.com",
    // "kirandeep.kaur@mordorintelligence.com",
    // "kartheek@mordorintelligence.com",
    // "satyananth@mordorintelligence.com",

    const [emailList, setEmailList] = useState([]);
    const [tabName ,setTabName ] = useState('')

    const getEmail = async () => {
      try {
        const params = { client_email: userData.email };
        console.log(">>>>>", params);
        
        const res = await axios.get(`${API.Base_URL}/power-bi`, { params });
        
        // Check for a 404 status code
        if (res.status === 404) {
          console.warn("No data found for the given email.");
          return; // Exit if no data is found
        }
        
        const data = res?.data?.data;
        if(data?.[0].tab_name){
          setTabName(data[0].tab_name)
        }
    
        // Check if the data is an array
        if (Array.isArray(data)) {
          const emails = data.filter(item => item.is_active).map(item => item.client_email).filter(email => email); // Extract emails
          setEmailList(emails); // Update state with the new email list
        } else {
          console.warn("Unexpected data format:", data);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          // Handle specific Axios errors
          console.error("Axios error fetching emails:", err.response?.data || err.message);
        } else {
          // Handle any other errors
          console.error("Error fetching emails:", err);
        }
      }
    };
    
  
    useEffect(() => {
      // Fetch emails only once when the component mounts
      if(userData.email!==undefined){
      getEmail();
    }
    }, [userData.email]); // Empty dependency array means this runs once on mount  
  

  return (
    <AppBar
      position="static"
      // sx={{ mb: "30px", background: "white", height: "58px" }}
      sx={{
        width: "100%",
        mb: "30px",
        height: bannerHeight,
        margin: "auto",
        boxShadow: "none",
        zIndex: "1",
        backgroundImage:
          "url('https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/Banner.svg')",
        backgroundSize: "cover", // You can adjust this as needed
        backgroundPosition: "center center",
        backgroundColor: "transparent",
      }}
    >
      <Box
        // maxWidth="xl"
        sx={{
          width: "90%",
          margin: "auto",
          background: "white",
          borderRadius: "10px",
          marginTop: "15px",
          height: "70px",
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to={"/reports"}>
              <img
                className="pic"
                style={{ width: "142px", height: "36px", marginLeft: "20px" }}
                // src="https://subscription-public.s3.us-west-2.amazonaws.com/static-assets/images/logo.png"
                src={SynapseLogo}
                alt="logo"
              />
            </Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                  <NavLink
                    to={page.link}
                    style={({ isActive }) => ({
                      width: "100%",
                      textDecoration: "none",
                      borderRadius: "4px",
                      color: isActive ? "#fff" : "black",
                      background: isActive ? "#2c8ebf" : "#fff",
                    })}
                  >
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{
                        width: "100%",
                        py: "10px",
                        px: 3,
                        color: "inherit",
                        display: "block",
                        textTransform: "capitalize",
                      }}
                    >
                      {page.id}
                    </Button>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <Link to={"/reports"}>
              <img
                style={{ width: "200px" }}
                src="https://ic.mordorintelligence.com/static/media/desktop_logo.d9e7c1db.png"
                alt="logo"
              />
            </Link>
          </Box>

          {/* <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",

              // justifyContent: "start",
              // ml: "11%",
            }}
          >
            <NavLink
              to="/reports"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#fff" : "black",
                background: isActive ? "#2c8ebf" : "#fff",
                // borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "70px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Reports
              </Button>
            </NavLink>
            <NavLink
              to="/hubs"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#fff" : "black",
                // background: isActive ? "#2c8ebf" : "#fff",
                borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "58px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Hubs
              </Button>
            </NavLink>
            <NavLink
              onClick={() => sessionStorage.removeItem("companies")}
              to="/companies"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#fff" : "black",
                // background: isActive ? "#2c8ebf" : "#fff",
                borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "58px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Companies
              </Button>
            </NavLink>
            <NavLink
              to="/indicators"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#fff" : "black",
                // background: isActive ? "#2c8ebf" : "#fff",
                borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "58px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Indicators
              </Button>
            </NavLink>
            <NavLink
              onClick={() => sessionStorage.removeItem("customData")}
              to="/custom-data"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#fff" : "black",
                background: isActive ? "#2c8ebf" : "#fff",
                // borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "58px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Custom Data
              </Button>
            </NavLink>
          </Box> */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center",
              alignItems: "center",

              // justifyContent: "start",
              // ml: "11%",
            }}
          >
            <NavLink
              to="/hubs"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#2c8ebf" : "black",
                // background: isActive ? "#2c8ebf" : "#fff",
                borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "66px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Hubs
              </Button>
            </NavLink>
            <NavLink
              to="/reports"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#2c8ebf" : "black",
                // background: isActive ? "#2c8ebf" : "#fff",
                borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "66px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Reports
              </Button>
            </NavLink>
            <NavLink
              onClick={() => sessionStorage.removeItem("companies")}
              to="/companies"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#2c8ebf" : "black",
                // background: isActive ? "#2c8ebf" : "#fff",
                borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Button
                onClick={handleCloseNavMenu}
                sx={{
                  // height: "54px",
                  height: "66px",
                  py: "10px",
                  px: 3,
                  color: "inherit",
                  display: "block",
                  textTransform: "capitalize",
                  borderRadius: "none",
                }}
              >
                Companies
              </Button>
            </NavLink>
            {userData && emailList.includes(userData.email) && (
              <NavLink
                to="/powerbi"
                style={({ isActive }) => ({
                  textDecoration: "none",
                  margin: "0 10px",
                  color: isActive ? "#2c8ebf" : "black",
                  borderBottom: isActive ? "4px solid #2c8ebf" : "none",
                })}
              >
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    height: "66px",
                    py: "10px",
                    px: 3,
                    color: "inherit",
                    display: "block",
                    textTransform: "capitalize",
                    borderRadius: "none",
                  }}
                >
                  {tabName ? tabName : " PowerBI Report"}
                </Button>
              </NavLink>
            )}

            <NavLink
              onClick={() => sessionStorage.removeItem("global-myra")}
              to="/myra-ai"
              style={({ isActive }) => ({
                textDecoration: "none",
                margin: "0 10px",
                // color: "black",
                color: isActive ? "#2c8ebf" : "black",
                // background: isActive ? "#2c8ebf" : "#fff",
                borderBottom: isActive ? "4px solid #2c8ebf" : "none",
              })}
            >
              <Box
                sx={{ height: "66px", display: "flex", alignItems: "center" }}
              >
                <MyRABtn />
              </Box>
            </NavLink>
          </Box>

          <Box sx={{ flexGrow: 0, marginRight: "20px" }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{
                    textTransform: "capitalize",
                    border: "2px solid #189CDE",
                  }}
                  alt={userInfo?.name || userInfo?.username}
                >
                  {" "}
                  {userInfo?.name?.charAt(0) || userInfo?.username?.charAt(0)}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <Box
                sx={{
                  width: "318px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                  padding="16px 24px 0px 24px"
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "800",
                        lineHeight: "25.6px",
                        textTransform: "capitalize",
                      }}
                    >
                      {userInfo?.name || userInfo?.username}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#5a5a5a",
                        lineHeight: "18px",
                        py: "2px",
                      }}
                    >
                      {userInfo?.email}
                    </Typography>
                    {userInfo?.mobile?.phone_number && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#5a5a5a",
                          lineHeight: "18px",
                        }}
                      >
                        {userInfo?.mobile.country_code}{" "}
                        {userInfo?.mobile.phone_number}
                      </Typography>
                    )}
                  </Box>
                  {/* its for edit user porfile */}
                  {/* <IconButton aria-label="delete" size="small">
                    <FiEdit
                      style={{
                        width: "21px",
                        height: "21px",
                        color: "#002F75",
                      }}
                    />
                  </IconButton> */}
                </Box>

                <Box
                  sx={{
                    marginTop: "5px",
                    background:
                      "linear-gradient(to right,#189CDE 0%,#EAFFEA 0%,#EAF6FE 60%,#E3E1FC 115%)",
                    padding: "16px 24px",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <img
                      src={Credit}
                      style={{
                        height: "20px",
                        width: "20px",
                        marginLeft: "-5px",
                        marginRight: "5px",
                      }}
                    />

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                      }}
                    >
                      Credits
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#5a5a5a",
                      marginLeft: "20px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <span>
                      Remaining Ondemand Credits : {remaining_ondemand_credit}{" "}
                    </span>
                    <img
                      className="credit-info-icon"
                      src={InfoIcon}
                      onMouseOver={(e) => handleCreditInfo("Ondemand")}
                      onMouseLeave={(e) => setIsCreditInfoModalOpen(false)}
                    />
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#5a5a5a",
                      marginLeft: "20px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <span>
                      Remaining Library Credits : {remaining_library_credit}{" "}
                    </span>
                    <img
                      className="credit-info-icon"
                      src={InfoIcon}
                      onMouseOver={(e) => handleCreditInfo("Library")}
                      onMouseLeave={(e) => setIsCreditInfoModalOpen(false)}
                    />
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#5a5a5a",
                      marginLeft: "20px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <span>myRA AI Credits : {remaining_myra_credits} </span>
                  </Typography>

                  {isCreditInfoModalOpen ? (
                    <Box className={"credit-info-popover"}>
                      <Typography sx={{ color: "#5a5a5a", fontSize: "13px" }}>
                        Total {creditType} Credits :{" "}
                        <span style={{ color: "#1BB1F0" }}>
                          {" "}
                          {creditType === "Ondemand"
                            ? total_ondemand_credit
                            : total_library_credit}{" "}
                        </span>
                      </Typography>
                      <Typography sx={{ color: "#5a5a5a", fontSize: "13px" }}>
                        Remaining {creditType} Credits :{" "}
                        <span style={{ color: "#1BB1F0" }}>
                          {creditType === "Ondemand"
                            ? remaining_ondemand_credit
                            : remaining_library_credit}{" "}
                        </span>
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
                <Box
                  sx={{
                    // background:"linear-gradient( rgba(24, 156, 222, 0.33), rgba(234, 255, 234, 1), rgba(234, 246, 254, 1), rgba(227, 225, 252, 1) )",
                    background:
                      "linear-gradient(to right,#189CDE 0%,#EAFFEA 0%,#EAF6FE 60%,#E3E1FC 115%)",
                  }}
                >
                  <Box sx={{ padding: "16px 24px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AccessTimeIcon
                        sx={{ marginLeft: "-5px", marginRight: "5px" }}
                      />
                      <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                        Analyst Hours
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {!isAnalystInfoModalOpen ? (
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#5a5a5a",
                            lineHeight: "36px",
                            py: "2px",
                            marginLeft: "20px",
                          }}
                        >
                          Remaining Analyst Hours :{" "}
                          {userInfo?.remaining_analyst_hours || 0}
                        </Typography>
                      ) : (
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#5a5a5a",
                              lineHeight: "18px",
                              marginLeft: "20px",
                            }}
                          >
                            Total Analyst Hours : {userInfo?.analyst_hours || 0}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#5a5a5a",
                              lineHeight: "18px",
                              py: "2px",
                              marginLeft: "20px",
                            }}
                          >
                            Remaining Analyst Hours :{" "}
                            {userInfo?.remaining_analyst_hours || 0}
                          </Typography>
                        </Box>
                      )}
                      <img
                        style={{
                          marginTop: "10px",
                          height: "18px",
                          width: "18px",
                          cursor: "pointer",
                        }}
                        src={InfoIcon}
                        onMouseOver={(e) => setIsAnalystInfoModalOpen(true)}
                        onMouseLeave={(e) => setIsAnalystInfoModalOpen(false)}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    // background:"linear-gradient( rgba(24, 156, 222, 0.33), rgba(234, 255, 234, 1), rgba(234, 246, 254, 1), rgba(227, 225, 252, 1) )",
                    background:
                      "linear-gradient(to right,#189CDE 0%,#EAFFEA 0%,#EAF6FE 60%,#E3E1FC 115%)",
                  }}
                >
                  <Box sx={{ padding: "16px 24px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={RelationshipManager}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginLeft: "-5px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                        My Relationship Manager
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#5a5a5a",
                        lineHeight: "18px",
                        py: "2px",
                        marginLeft: "20px",
                      }}
                    >
                      {userInfo?.relationship_manager?.[0]?.username}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "400",
                        color: "#5a5a5a",
                        lineHeight: "18px",
                        marginLeft: "20px",
                      }}
                    >
                      {userInfo?.relationship_manager?.[0]?.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <MenuItem
                style={menuItemStyle}
                onClick={() => {
                  navigate("/bookmarks");
                  handleCloseUserMenu();
                }}
              >
                <Box width={200}>
                  <Typography fontSize="14px">Bookmarks</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                style={menuItemStyle}
                disabled={userInfo?.account_type !== "owner"}
                onClick={() => {
                  navigate("/user-management");
                  handleCloseUserMenu();
                }}
              >
                <Box width={200}>
                  <Typography fontSize="14px">User Management</Typography>
                </Box>
              </MenuItem>
              {/* <MenuItem
                style={menuItemStyle}
                onClick={() => {
                  navigate("/credits");
                  handleCloseUserMenu();
                }}
              >
                <Box width={200}>
                  <Typography fontSize="14px">Credits</Typography>
                </Box>
              </MenuItem> */}
              <MenuItem
                style={menuItemStyle}
                onClick={() => {
                  navigate("/purchase-history");
                  handleCloseUserMenu();
                }}
              >
                <Box width={200}>
                  <Typography fontSize="14px">Purchase History</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                style={menuItemStyle}
                onClick={() => {
                  navigate("/change-password");
                  handleCloseUserMenu();
                }}
              >
                <Box width={200}>
                  <Typography fontSize="14px">Change Password</Typography>
                </Box>
              </MenuItem>
              <MenuItem
                style={{
                  padding: "16px 24px",
                  color: "#CF0A0A",
                  fontSize: "14px",
                }}
                onClick={() => {
                  // destroy the cookie
                  cookies.remove("TOKEN", { path: "/" });
                  //const element = document.getElementById('fc_frame');
                  //element.remove();
                  navigate("/");
                }}
              >
                <Typography width="100%" fontSize="14px">
                  Log Out
                </Typography>
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  navigate("/users");
                }}
              >
                <Typography width="100%" >
                  Users
                </Typography>
              </MenuItem> */}
            </Menu>
          </Box>
        </Toolbar>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "90%",
          margin: "auto",
        }}
      >
        {/* <Button sx={{ backgroundColor: 'transparent', color: 'black', marginTop:"10px"}}>
            <ChevronLeftSharpIcon /> My Hubs{ backButtonData}
          </Button> */}
        {/* : ""} */}
      </Box>
      <Box sx={{ width: `${!backButtonData ? "85%" : "90%"}`, margin: "auto" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "70%",
            textAlign: "left",
          }}
        >
          {backButtonData ? (
            <Button
              onClick={() => {
                navigate(-1);
              }}
              sx={{
                backgroundColor: "transparent",
                color: "black",
                marginTop: "20px",
                marginLeft: 0,
                padding: "4px",
              }}
            >
              <ChevronLeftSharpIcon />
            </Button>
          ) : (
            ""
          )}
          <Box>
            {backButtonData ? (
              <Link to={"/hubs"}>
                <Box my={3}>
                  <Typography color="grey">{backButtonData}</Typography>
                </Box>
              </Link>
            ) : (
              ""
            )}

            <Typography
              sx={{
                textTransform: "capitalize",
                fontWeight: "800",
              }}
              variant="h5"
              my={3}
              color="black"
            >
              {hubTitleWordsModify(heading)}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                // fontWeight: "800"
              }}
              // variant="body"
              mt={3}
              color="black"
            >
              {subHeading}
            </Typography>
            <Typography
              sx={{
                textTransform: "capitalize",
                // fontWeight: "800"
              }}
              // variant="body"
              mt={1}
              color="blue"
            >
              {blueHeading}
            </Typography>

            <Typography my={1} color="grey">
              <Para paragraph={paragraph} />
            </Typography>
            <Box sx={{ marginBottom: "2rem" }}>
              {talkToUs ? (
                <Button
                  startIcon={<Email />}
                  style={{
                    backgroundColor: "#189CDE",
                    color: "white",
                    borderRadius: "8px",
                    textTransform: "none",
                    marginRight: "1rem",
                  }}
                  variant="contained"
                  onClick={() => {
                    setDrawerOpen(true);
                  }}
                >
                  Talk to Us
                </Button>
              ) : (
                ""
              )}

              {/* {downloadBrochure ? (
                <Button
                  startIcon={<Download />}
                  style={{
                    color: "#002F75",
                    borderRadius: "8px",
                    textTransform: "none",
                    backgroundColor: "#FFF",
                  }}
                  variant="outlined"
                  onClick={downloadingBrochure}
                >
                   Download Brochure
                </Button>
              ) : (
               ""
              )} */}
              {downloadBrochure && hasAccess && (
                <Button
                  startIcon={<Download />}
                  style={{
                    color: "#002F75",
                    borderRadius: "8px",
                    textTransform: "none",
                    backgroundColor: "#FFF",
                  }}
                  variant="outlined"
                  onClick={downloadingBrochure}
                >
                  Download Brochure
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        <RenewDrawer
          title={"Talk To Us"}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          typeModal={"contact"}
          fromHub={hubTitleWordsModify(heading)}
        />
        {/* <HubsHomeChatDialog
          open={open}
          setOpen={setOpen}
          notify={notify}
          setNotify={setNotify}
        /> */}
      </Box>
      <div id="navbar-bottom" /> {/* DO NOT REMOVE THIS DIV */}
    </AppBar>
  );
}
export default Navbar;

const Para = ({ paragraph }) => {
  const searchTerm = "Enter Synapse";

  // Check if the paragraph contains the searchTerm
  if (paragraph?.includes(searchTerm)) {
    // Split the paragraph into two parts based on the searchTerm
    const parts = paragraph.split(searchTerm);

    return (
      <>
        <span>{parts[0]}</span>
        <br />
        <br />
        <span>{searchTerm + parts[1]}</span>
      </>
    );
  } else {
    // Render the entire paragraph if searchTerm is not found
    return <span>{paragraph}</span>;
  }
};
